<template>
  <div id="banner">
    <div class="banner-title">
      Earn <img src="../assets/images/Yuzu.png" class="yuzu-logo" /> Yuzu Points with
      Grasp Academy as part of the Open Campus EDU Chain mainnet Launch Program
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
@import "../assets/styles/mixins.scss";

#banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: $grasp-orange;

  .banner-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-weight: 600;
    padding: 10px 0;

    .yuzu-logo {
      width: 20px;
    }
  }
}
</style>
